import { format, parse } from "date-fns";
import get from "lodash-es/get.js";
import { animateScroll } from "react-scroll";

export function fontMetrics(
	psSize: number,
	psLeading: number,
	psTracking: number,
) {
	return {
		fontSize: `${psSize / 10}rem`,
		lineHeight: `${psLeading / psSize}%`,
		letterSpacing: `${psTracking / 1000}em`,
	};
}

export function adding(a: number, b: number) {
	return a + b;
}

export function randomArrayValue(array) {
	return array[Math.floor(Math.random() * array.length)];
}
function pad(num, size) {
	let s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}
export function randomNum(max, places = null) {
	const f = Math.floor(Math.random() * max);

	if (!places) return f;
	return `${pad(f, places)}`;
}

export const scrollToHeader = (options = {}) => {
	setTimeout(() => {
		animateScroll.scrollToTop({
			duration: 100,
			delay: 0,
			smooth: true,
			...options,
		});
		// scroller.scrollTo(`scrollTarget`, {
		//   duration: 100,
		//   delay: 0,
		//   smooth: true,
		//   ...options,
		// });
	}, 0);
};

export const getPlaceholder = (placeholder, label) => {
	if (placeholder === false) {
		return null;
	}
	return placeholder;
};

export function randomDealNumber(length) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}
export function randomNumber(length) {
	let result = "";
	const characters = "012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function makeId(length) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const setIfNot = (formContext, path, value) => {
	const { setFieldValue } = formContext;

	if (!get(formContext.values, path)) {
		setFieldValue(path, value);
	}
};

export const convertDateToIso8601 = (date_string) => {
	if (!date_string) {
		return date_string;
	}
	try {
		return format(parse(date_string, "MM/dd/yyyy", new Date()), "yyyy-MM-dd");
	} catch (error) {
		try {
			return format(parse(date_string, "MM-dd-yyyy", new Date()), "yyyy-MM-dd");
		} catch (error) {
			return date_string;
		}
	}
};

export const convertDateToMMDDYYYY = (date_string) => {
	if (!date_string) {
		return date_string;
	}
	try {
		return format(parse(date_string, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");
	} catch (error) {
		return date_string;
	}
};

export const focusNextElement = () => {
	const focussableElements = "input";
	if (document.activeElement) {
		const focussable = Array.prototype.filter.call(
			document.querySelectorAll(focussableElements),
			(element) =>
				element.offsetWidth > 0 ||
				element.offsetHeight > 0 ||
				element === document.activeElement,
		);
		const index = focussable.indexOf(document.activeElement);
		if (index > -1) {
			const nextElement = focussable[index + 1] || focussable[0];
			nextElement.focus();
		}
	}
};

export const handleSidebarNavigationUpDown = (event) => {
	const links = Array.from(
		document.querySelectorAll("[id=panel-link]"),
	) as HTMLAnchorElement[];
	const currentIndex = links.findIndex(
		(link) => link.href == window.location.href.split("?")[0],
	);
	if (currentIndex > -1) {
		if (event.key === "ArrowUp") {
			links[currentIndex - 1].click();
		}
		if (event.key === "ArrowDown") {
			links[currentIndex + 1].click();
		}
	}
};
